<template>
  <div class="page">
    <Panel lineColor="var(--primary-color)">
      <h4 class="text-center">Dados básicos</h4>
      <LineDivider></LineDivider>
      <v-row>
        <v-col cols="12" md="8">
          <FormField
            label="Razão Social"
            v-model="form.razaoSocial"
            :validation="$v.form.razaoSocial"
            :loading="isLoading"
          />
        </v-col>
        <v-col cols="12" md="4">
          <FormField
            label="CNPJ/CPF"
            v-model="form.cpf"
            :validation="$v.form.cpf"
            :loading="isLoading"
          />
        </v-col>
        <v-col cols="12" md="8">
          <FormField
            label="Nome Fantasia"
            v-model="form.nomeFantasia"
            :validation="$v.form.nomeFantasia"
            :loading="isLoading"
          />
        </v-col>
        <v-col cols="12" md="4">
          <FormSelect
            label="Segmento"
            :options="segmentos"
            v-model="form.idsegmento"
            :validation="$v.form.idsegmento"
            :loading="isLoading"
          />
        </v-col>
        <v-col cols="12">
          <FormText
            :rounded="false"
            :rows="13"
            readonly
            label="Termo de uso"
            v-model="form.termoUso"
            :loading="isLoading"
          />
        </v-col>
        <v-col cols="12" md="6" class="text-start">
          <FormSwitch
            v-model="form.aceiteTermo"
            label="Eu li e concordo com os termos de uso"
            :loading="isLoading"
          />
          <small :class="!form.aceiteTermo ? 'red--text' : 'transparent--text'">
            <template>É obrigatório aceitar os termos de uso</template>
          </small>
        </v-col>
        <v-col cols="12" md="6" class="text-center text-md-end">
          <FormButton
            :loading="isLoading"
            :disabled="!form.aceiteTermo"
            type="primario"
            @click="onSubmit()"
            text="Salvar"
          />
        </v-col>
      </v-row>
      <DeploymentMessage :show="showWizard" :step="stepWizard" />
    </Panel>
  </div>
</template>

<script>
import Panel from '@/components/Panel'
import FormField from '@/components/form/FormField'
import FormText from '@/components/form/FormText'
import FormSelect from '@/components/form/FormSelect'
import FormButton from '@/components/form/FormButton'
import FormSwitch from '@/components/form/FormSwitch'
import LineDivider from '@/components/LineDivider'
import DeploymentMessage from '@/components/DeploymentMessage'
import { mapGetters } from 'vuex'
import {
  DADOS_EMPRESA_SAVE,
  DADOS_EMPRESA_LOAD
} from '@/store/actions/dadosEmpresa'
import { ADMIN_GET_PASSOS_IMPLANTACAO } from '@/store/actions/admin'
import { SEGMENTO_LIST_ALL } from '@/store/actions/segmento'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'DadosEmpresa',
  components: {
    Panel,
    FormField,
    FormText,
    FormSelect,
    FormSwitch,
    FormButton,
    LineDivider,
    DeploymentMessage
  },
  data: () => ({
    isLoading: false,
    form: {
      razaoSocial: '',
      nomeFantasia: '',
      cpf: '',
      idsegmento: '',
      termoUso: '',
      aceiteTermo: false
    },
    segmentos: [],
    wizardPath: 'informacoes-loja',
    showWizard: '',
    stepWizard: {}
  }),
  validations: {
    form: {
      nomeFantasia: { required },
      razaoSocial: { required },
      idsegmento: { required },
      cpf: { required }
    }
  },
  computed: {
    ...mapGetters([
      'getFornecedorId',
      'getFornecedor',
      'moduloFornecedorAtivo'
    ]),
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  methods: {
    getPassosImplantacao() {
      this.isLoading = true
      this.$store
        .dispatch(ADMIN_GET_PASSOS_IMPLANTACAO, {
          execute: `${true}`,
          step: this.wizardPath
        })
        .then(data => {
          this.isLoading = false
          if (data && data[0]) {
            this.stepWizard = data[0]
          }
        })
        .catch(err => {
          const erro =
            err.response.data.Mensagem ||
            err.response.data ||
            'Falha ao buscar os passos de implantação'
          this.$vueOnToast.pop('error', erro)
          this.isLoading = false
        })
    },
    getSegmentos() {
      this.isLoading = true
      this.$store
        .dispatch(SEGMENTO_LIST_ALL, false)
        .then(data => {
          this.segmentos = (data || []).map(v => {
            return { value: v.idsegmento, text: v.nome }
          })
          this.isLoading = false
          this.getData()
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    getData() {
      this.isLoading = true
      this.$store
        .dispatch(DADOS_EMPRESA_LOAD, false)
        .then(data => {
          this.form = {
            ...this.form,
            razaoSocial: data.razao_social,
            nomeFantasia: data.nome,
            cpf: data.nrdocumento,
            idsegmento: data.idsegmento,
            termoUso: data.termo
          }
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    getDocumentType(cpf) {
      const c = (cpf || '').replace(/[^0-9]/g, '')
      return c.length <= 11 ? 'CPF' : 'CNPJ'
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      if (!this.form.aceiteTermo) return
      const data = {
        razao_social: this.form.razaoSocial,
        nome: this.form.nomeFantasia,
        nrdocumento: this.form.cpf,
        tipodocumento: this.getDocumentType(this.form.cpf || ''),
        idsegmento: this.form.idsegmento
      }
      this.$store
        .dispatch(DADOS_EMPRESA_SAVE, data)
        .then(() => {
          if (this.getFornecedor.publicado) {
            this.$vueOnToast.pop('success', 'Dados básicos salvos com sucesso.')
          } else {
            this.showWizard = 'sucesso'
            this.getPassosImplantacao()
          }
          this.isLoading = false
        })
        .catch(err => {
          const erro =
            err.response.data.Mensagem ||
            err.response.data ||
            'Não foi possivel salvar os dados da empresa'
          this.$vueOnToast.pop('error', erro)
          this.isLoading = false
        })
    }
  },
  created() {
    this.getSegmentos()
  }
}
</script>
